import { defineStore } from "pinia";
import { ARTICLES_PER_PAGE } from "~/constants";
import type { Article } from "~/models";

export const useBlogStore = defineStore("blog", () => {
  const articlesFilteredByCategory = ref<Article[]>([]);
  const progressReadingPercent = ref("0%");
  const progressBarIsActive = ref(false);

  const pagination = ref({
    page: 0,
    total: 0,
  });

  const getReadingProgressPercent = computed<string>(() => progressReadingPercent.value);

  const offset = computed<number>(() => pagination.value.page * ARTICLES_PER_PAGE);

  function incrementPage() {
    pagination.value.page++;
  }

  function resetPagination() {
    pagination.value.page = 0;
  }

  function setArticles(articles: Article[]) {
    articlesFilteredByCategory.value = [...articles];
  }

  function setProgressBar(isActive: boolean) {
    progressBarIsActive.value = isActive;
  }

  return {
    progressBarIsActive,
    progressReadingPercent,
    offset,
    incrementPage,
    resetPagination,
    setArticles,
    getReadingProgressPercent,
    setProgressBar,
  };
});
